import * as React from "react"
import Layout from '../components/Layout'
import tw from 'twin.macro';
import styled from 'styled-components';

import { HeroSubTitle , FormContain } from '../styles/base'

const TeamContain = styled.div`
  ${tw`pb-2 mb-2 max-w-3xl mx-auto text-sm`}
`


const CareerPage = () => {
  return (
    <Layout>
    <div tw='bg-gray-200 pt-8 px-7'>
          <HeroSubTitle tw='max-w-3xl  mt-5 text-center mx-auto px-7 md:px-5 mb-16 font-gibson'>
            Adjmi Apparel Careers
          </HeroSubTitle>

              <TeamContain>
                We offer exciting career opportunities as well as benefits that truly focus on the needs of our employees. Each employee plays an integral part in our company's success.
                We look to develop a well-rounded team who want to learn and grow in their career. Whether you are an experienced professional or a recent graduate, we have so much to offer!
	  	<br/><br/>
		*MRF Hub websitelink: 
	  	<a href="https://alliedbenefit.sapphiremrfhub.com"> https://alliedbenefit.sapphiremrfhub.com</a>
	  	<br /><br />
                For employment consideration, please send us your resume and cover letter by filling out the form below.	
              </TeamContain>

              <FormContain>
              <form name='Careers Page' method="POST" data-netlify="true" action='/thanks-career' enctype="multipart/form-data">
                <div tw='flex flex-col max-w-3xl mx-auto space-y-3 py-8'>
                  <input type="hidden" name="form-name" value="Careers Page" />
                  <input type="hidden" name="MAX_FILE_SIZE" value="4194304" />
                  <label htmlFor='department'>Select Career Opportunity</label>
                  <select name='department'>
                    <option value='Production'>Production</option>
                    <option value='Design'>Design</option>
                    <option value='Sales'>Sales</option>
                    <option value='Accounting'>Accounting</option>
                    <option value='Information Systems'>Information Systems</option>
                    <option value='Import'>Import</option>
                    <option value='Finance'>Finance</option>
                    <option value='Human Resource'>Human Resources</option>
                    <option value='General Office'>General Office</option>
                  </select>
                  <label htmlFor='name'>Name</label>
                  <input type='text' name='name' />
                  <label htmlFor='email'>Email</label>
                  <input type='email' name='email' />
                  <label htmlFor='cover-letter'>Cover Letter</label>
                  <textarea name='cover-letter' rows="10"></textarea>
                  <label htmlFor='resume'>Attach Resume</label>
                  <input name='resume' type="file" />
                  <input name='submit' tw="block mt-16 w-48 bg-gray-700 text-white rounded cursor-pointer" type='submit' value='Send' />
                </div>
              </form>
              </FormContain>
    </div>
    </Layout>
  )
}

export default CareerPage
